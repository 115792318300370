import axios from "axios"
import { navigate } from "gatsby"
import { loadStripe } from "@stripe/stripe-js/pure"
const stripePromise =
  process.env.NODE_ENV === "production"
    ? "pk_live_e5ysjmA4BTHpPpnwOrbB1dGd"
    : "pk_test_t0wDGDr5oNtXlBdEu3jykxr5"
axios.defaults.baseUrl = "http://localhost:9000http://localhost:9000"
//AUTH FUNCS
export const sendLogin = (creds, cb) => {
  axios
    .post("/api/users/login", creds)
    .then(res => {
      console.log("res received")
      return cb(null, creds.email)
    })
    .catch(err => {
      cb(err)
    })
}
export const signupUser = userObject => {
  return axios
    .post("/api/users/signup", userObject)
    .then(res => {
      localStorage.setItem("studentData", res.data)
    })
    .catch(err => {
      if (err.response && err.response.data) {
        alert(err.response.data)
      } else if (err.response) {
        alert(
          "Uh oh. Something went wrong. Either your account already exists, or one of the fields you entered was incorrect."
        )
      } else {
        alert(
          "Network error - Try again; Looks like there's something wrong with your internet. Maybe it's spotty? We weren't able to create an account. If the issue persists, please email us at info@99point9prep.com"
        )
      }
    })
}
export const sendLogout = (creds, cb) => {
  axios
    .get("/api/users/logout")
    .then(res => {
      cb(res)
    })
    .catch(err => {
      console.log(err)
    })
}
//Course Schedule Page
export const schedulePageData = (courseName, cb) => {
  return axios
    .get(`/api/fetch/course/${courseName}`)

    .then(result => {
      cb(result.data)
    })
    .catch(err => {
      console.log(err)
    })
}
//CHECKOUT FUNCS    //src/components/authsection/checkout.js
//Step 0.
export const checkoutMultiGetCourseInfo = (query, cb) => {
  return axios
    .post(`/api/fetch/bucketItems`, {
      query,
    })
    .then(result => {
      cb(result.data)
    })
    .catch(err => {
      console.log(err)
    })
}
//Step 1.
export const sendCheckoutStep1Form = data => {
  return axios.post(`/api/stripe/startEnrollment`, data).catch(err => {
    console.log(err)
    return
  })
}
//Step 2.
export const sendCheckoutStep2Form = data => {
  return axios
    .post(`/api/stripe/startEnrollment`, data)
    .then(res => {})
    .catch(err => {
      console.log(err)
      return
    })
}
//Step 3.
export const updatePaymentStatus = status => {
  axios.post("/api/stripe/updatePaymentStatus", status)
}
//Step 4.
export const fetchStripe = async (
  step1FormData,
  step2FormData,
  courseInfo,
  handleStripeRedirect
) => {
  let payload = Object.assign({}, step1FormData)
  payload.servicesRequested = courseInfo
  payload.discountsRequested = step2FormData
  console.log(payload)
  if (payload) {
    axios
      .post("/api/stripe/create-checkout-session", payload, {
        withCredentias: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(function (response) {
        return handleStripeRedirect(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  } else {
    navigate("/app/signup")
  }
}

//CONTACT FORM
export const handleContactForm = (data, cb) => {
  return axios
    .post(`/api/fetch/contact/`, data)
    .then(resp => {
      cb(null, resp.data)
    })
    .catch(err => {
      console.log(err)
      cb(err.response)
    })
}

//Profile
export const getCurrentEnrolments = async (query, cb) => {
  let param = {
    params: {},
  }
  if (query !== "all") {
    param.params.filter = query
  }
  return await axios
    .get("/api/student/courses", param)
    .then(res => {
      if (res.data && Array.isArray(res.data)) {
        cb(res.data)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export const redirToBilling = async () => {
  let url = await axios.get("/api/stripe/redirectToBilling")
  if (typeof window !== "undefined") {
    window.location.replace(url)
  }
}

//STUDENT DASHBOARD

export const getAbsEntries = (currentTab, cb) => {
  axios
    .get("/api/student/abs_entries/users", { params: { by: "employment" } })
    .then(res => {
      cb(res.data)
    })
    .catch(err => {
      console.log(err)
    })
}
export const saveAbsEntry = (docId, body, cb) => {
  axios
    .put(`/api/student/abs_entries/${docId}`, body)
    .then(res => {
      console.log(res.data)
      cb(null, res.data)
    })
    .catch(err => {
      console.log(err)
      cb(err)
    })
}

export const createNewAbsEntry = (userId, body, cb) => {
  console.log("sending shit")
  axios
    .post(`/api/student/abs_entries/${userId}`, body)
    .then(res => {
      cb(null, true)
    })
    .catch(err => {
      console.log(err)
      cb(err)
    })
}

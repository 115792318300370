import React from "react"
import styled from "styled-components"
//Elements
import { CtaPrimary, CtaSecondary } from "../elements/buttons.js"
import { CtaHeading, SectionTitle, SectionDescription } from "../typography.js"

const sectionPaddingTop = `${116 * 1}px`
const sectionPaddingBottom = `${116 / 2}px`

export const Section = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "inherit"};
  color: "inherit";
  padding-top: ${props =>
    props.reducePaddingTop ? sectionPaddingBottom : sectionPaddingTop};
  padding-bottom: ${sectionPaddingBottom};

  @media (min-width: 1080px) {
    max-width: ${props => (props.wide ? "100%" : "1080px")};
    padding-right: ${props => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    padding-left: ${props => (props.wide ? "calc((100vw - 1080px)/2)" : "0")};
    width: ${props => (props.wide ? "auto" : "100%")};
  }
  @media (max-width: 800px) {
    width: 80%;
    padding-right: auto;
    padding-left: auto;
    display: flex;
    justify-content: center;
  }

  // New stuff
  @media (max-width: 800px) {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  margin: ${props => (props.wide ? "0" : "0 auto")};
`

// ${props => props.wide ?}
export const BroadSection = styled(Section)`
  @media (min-width: 1080px) {
    max-width: 100%;
  }
  margin: 0;
  padding-right:
  padding-left:
`

const Card = styled.div`
  background-color: #7ed321;
  width: 100%;
  border-radius: 5px;
  height: 300px;
  background-color: #0c2e4e;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%230d1602' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  text-align: center;
`

const CtaContainer = styled.div`
  display: flex;
  width: 35%;
  flex-flow: row wrap;
  justify-content: space-between;
  content-align: center;
  flex-start: center;
  margin: 3em auto;
`
const FlexRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1em;
  justify-content: space-between;
`
const ColContainer = styled(SectionDescription)`
  width: 45%;
`
export const TextSection = ({ backcolor, heading, col1, col2 }) => {
  return (
    <Section backgroundcolor={backcolor}>
      <SectionTitle style={{ marginBottom: "1em" }}>{heading}</SectionTitle>
      <FlexRow>
        <ColContainer marginR={"2.5%"} marginL={"0"}>
          {col1}
        </ColContainer>
        <ColContainer marginL={"2.5%"} marginR={"0"}>
          {col2}
        </ColContainer>
      </FlexRow>
    </Section>
  )
}
export const CtaCard = ({ broadSection, wide }) => {
  return broadSection ? (
    <BroadSection>
      <Card>
        <CtaHeading center={"center"} color={"white"}>
          What would you like to do next?
        </CtaHeading>
        <CtaContainer>
          <CtaPrimary height={"20px"} label={"Sign up"} />
          <CtaSecondary
            height={"20px"}
            color={"white"}
            label={"Request Demo"}
          />
        </CtaContainer>
      </Card>
    </BroadSection>
  ) : (
    <Section wide={wide}>
      <Card>
        <CtaHeading center={"center"} color={"white"}>
          What would you like to do next?
        </CtaHeading>
        <CtaContainer>
          <CtaPrimary height={"20px"} label={"Sign up"} />
          <CtaSecondary
            height={"20px"}
            color={"white"}
            label={"Request Demo"}
          />
        </CtaContainer>
      </Card>
    </Section>
  )
}

export const HorizontalSection = styled.section`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  margin-top: 116px;
`
//Title Font.
// Title font: 38px; 34px;
// font-weight: 500;
// line-height: 1.263157895;
// letter-spacing: -0.2px; -0.1px
// font-family: "sohne-var","Helvetica Neue","Arial",sans-serif;

// Caption
//Font weight: 500;
// font-size: 18px;
// line-height: 1.556;
//letter spacing: 0.2px

// P:  18px;
// font-weight: 300px;

// link P:
// font-size: 18px;
// font-weight: 425;
//same

//What do you get text:
// --titleFontSize: 15px;
// --titleLineHeight: 1.6;
// --titleWeight: var(--fontWeightSemibold); 425
// --titleLetterSpacing: 0.2px;
// --paragraphGap: 8px;

import React from "react"
import styled from "styled-components"

export const HeroHeader = styled.header`
  @media (min-width: 1112px) {
    letter-spacing: -0.04em;
    position: relative;
    min-width: 0;
    text-align: ${props => props.float || "left"};
  }
  @media (min-width: 600px) {
    padding-right: 0px;
    font-size: 70px;
  }
`
const titleFontSize = `94px`
const titleFontWeight = `500`
const titleLineHeight = `1.04`
export const HeroTitleH1 = styled.h1`
  margin: ${props => (props.float ? "0 auto" : "100px 0 0 0")};
  color: black;
  font-size: ${props => (props.fontSize ? props.fontSize : titleFontSize)};
  font-weight: ${titleFontWeight}
  line-height: ${titleLineHeight};
  letter-spacing: -0.04em;
  min-width: 0;
  padding:  ${props => (props.float ? "0" : "0 0 0 16px")};
  width: 90%;
  text-align: ${props => (props.align ? props.align : "left")};
  @media (max-width: 880px) {
    font-size: 10vw;
    text-align: center;
  }
`

export const HeroTitle = ({ fontSize, align, float, children }) => {
  return (
    <HeroHeader float={float}>
      <HeroTitleH1
        style={{ width: "95%" }}
        align={float}
        fontSize={fontSize}
        float={float}
      >
        {children}
      </HeroTitleH1>
    </HeroHeader>
  )
}

const bodyFont = `18px`
export const HeroBody = styled.div`
  padding: 0 16px 16px;
  font-size: ${bodyFont};
  color: inherit;
  letter-spacing: 0.2px;
  line-height: 1.55556;
  font-weight: 400;

  @media (max-width: 880px) {
    text-align: center;
    font-size: 24px
    opacity: 0.8;
    color: inherit;
    margin: 0 auto;
    padding: 25px 16px 16px 16px;
  }
`
export const AsideCaption = styled.span`
  font-weight: 800;
  font-size: 12px;
  line-height: 1.5556;
  color: inherit;
  padding: 0;
  margin: 0;
  opacity: 0.6;
`
export const AsideTitle = styled.h3`
  font-size: 18px;
  line-height: 1.5556;
  font-weight: 700;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: ${props => props.float};
  padding-bottom: 1em;
  width: 100%;
  margin: 0 auto;
`
export const AsideDescription = styled.div`
  font-size: 16px;
  line-height: 1.5556;
  color: black;
  opacity: 0.8;
  width: 100%;
  margin: 0 auto;
`
export const SectionCaption = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5556;
  color: inherit;
  grid-area: 1 / 1 / 2 / 2;
  text-align: ${props => (props.center ? props.center : "left")};
  padding: 0;
  margin: 0;
  opacity: 0.8;
  letter-spacing: -0.003em;
  color: #f50057;
`
export const SectionTitle = styled.h1`
  font-weight: 700;
  font-size: ${props => (props.fontSize ? props.fontSize : "50px")};
  color: inherit;
  margin: 0;
  padding: 0;
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : "0")};
  text-align: ${props => (props.center ? props.center : "left")};
  grid-area: 2 / 1 / 3 / 2;
  letter-spacing: -0.04em;
`
export const SectionItemTitle = styled(SectionTitle).attrs({ as: "h3" })`
  font-size: 38px;
  font-weight: 600;
  line-height: 1.263157895;
  letter-spacing: -0.2px;
  @media (max-width: 880px) {
    letter-spacing: -0.1px;
    font-size: 34px;
  }
`

export const SectionDescription = styled.span`
  font-weight: 400;
  font-size: 22px;
  // color: ${props => (props.color ? props.color : "#425466")};
  text-align: ${props => (props.center ? props.center : "left")};
  color: inherit;
  opacity: 0.8;
  line-height: 1.5556;
  grid-area: 3 / 1 / 4 / 2;
  letter-spacing: -0.003em;
`
export const SectionItemBody = styled(SectionDescription)`
  font-size: 18px;
`
export const CtaHeading = styled.h1`
  font-size: 55px;
  text-align: center;
  color: white;
  font-weight: 700;
`
export const AccordianRowHeading = styled.h2`
  font-size: 19px;
  line-height: 1.5556;
  font-weight: 500;
  color: inherit;
  margin: 0;
  padding: 0;
`

export const EssayCardTitle = styled.h3`
  font-size: 18px;
  color: ${props => (props.color ? props.color : "white")};
  font-weight: 500;
  margin: 0;
`

import React from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import redirect from "../../services/redirect.js"
import Dropdown_v1, { OptionsDropdown } from "../molecules/dropdown.js"

const HoverArrow = styled.svg`
  position: relative;
  top: 1px;
  margin-left: 8px;
  strokewidth: 2;
  fill: none;
  stroke: currentColor;
`
const SVG = styled.svg`
  &:active {
    fill: #f50057;
  }
`
const transition = `150ms cubic-bezier(0.215,0.61,0.355, 1)`
export const OvalAnchor = styled(props => <Link {...props} />)`
  cursor: pointer;
  height: ${props => (props.height ? props.height : "34px")};
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: ${props =>
    props.height && parseInt(props.height, 10) > -1
      ? (parseInt(props.height, 10) / 2).toString() + "px 16px"
      : "16px"};
  border-radius: 50vh;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "hsla(0, 0%, 100%, 0.2)"};
  color: ${props => (props.color ? props.color : "#fff")};
  transition: ${transition};
  transition-property: background-color, opacity;
  white-space: nowrap;
  &:hover ${HoverArrow} {
    transition: ${transition};
    transform: translateX(3px);
  }
`

export const CtaPrimary = ({
  backgroundColor,
  color,
  height,
  label,
  link,
  style,
}) => {
  return (
    <OvalAnchor
      backgroundColor={backgroundColor}
      height={height}
      color={color}
      to={link}
      style={style}
    >
      {label}
      <HoverArrow width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
        <g fillRule="evenodd">
          <path className="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
        </g>
      </HoverArrow>
    </OvalAnchor>
  )
}

export const CtaSecondary = ({
  backgroundColor,
  color,
  height,
  label,
  link,
}) => {
  return (
    <OvalAnchor
      backgroundColor={"transparent"}
      color={color || "black"}
      height={height || "20px"}
      to={link}
    >
      {label}
      <HoverArrow width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
        <g fillRule="evenodd">
          <path className="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
        </g>
      </HoverArrow>
    </OvalAnchor>
  )
}

const Anchor = styled(OvalAnchor)`
  color: ${props => (props.active ? "#f50057" : "inherit")};
  font-weight: 400;
  &:hover {
    color: #f50057;
  }
  @media (max-width: 800px) {
    font-size: 12px;
    padding: 10px 12px;
  }
  @media (max-width: 485px) {
    font-size: 12px;
    padding: 10px 10px;
  }
`
export const SameSiteAnchor = ({ label, link, active, callback }) => {
  return (
    <Anchor
      to={link}
      backgroundColor={"transparent"}
      active={active}
      color={"inherit"}
      height={"20px"}
      ariaLabel={`Scroll to ${link}`}
      onClick={() => {
        callback(link, label)
      }}
      children={label}
    />
  )
}

export const SubmitButton = styled(OvalAnchor).attrs({
  as: "button",
})`
  outline: none;
  border: none;
  min-width: 4em;
  padding: 3px 2em;
  text-align: center;
  display: block;
  margin: 1em;
`
export const SubmitNext = styled(SubmitButton)`
  &::after {
    content: "  ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease-in-out;
    position: relative;
    left: 5px;
    top: -1px;
  }
  &:hover {
    &::after {
      transform: rotate(-45deg) translate(3px, 3px);
    }
  }
`
export const SubmitBack = styled(SubmitButton)`
  &::before {
    content: " ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    position: relative;
    right: 5px;
    top: -1px;
  }
  &:hover {
    &::before {
      transform: rotate(135deg) translate(-3px, -3px);
    }
  }
`
export const DropdownButton = OptionsDropdown

const keyframe1 = keyframes`
  0% {
     transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }

`
const keyframe2 = keyframes`
  0% {
      transform: translate3d(0, 0, 0) scale(1);
    }
    50% {
      transform: translate3d(24px, 0, 0) scale(0.5);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
    }
`
const keyframe3 = keyframes`
  0% {
      transform: translate3d(0, 0, 0) scale(1);
    }
    50% {
      transform: translate3d(-24px, 0, 0) scale(0.5);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
    }
`
const keyframe4 = keyframes`
   0% {
      transform: translate3d(0, 0, 0) scale(1);
    }
    50% {
      transform: translate3d(0, 24px, 0) scale(0.5);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
    }
`
const keyframe5 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -24px, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`
const Loader = styled.div`
  height: 32px;
  width: 32px;
  animation: ${keyframe1} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 50%;
    animation: ${keyframe2} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 7px;
    height: 7px;
    background: #fff;
    border-radius: 50%;
    animation: ${keyframe3} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  & span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
  }
  & span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    animation: ${keyframe4} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  & span::after {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    animation: ${keyframe5} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
`

export const LoadAnimation = () => (
  <Loader>
    <span></span>
  </Loader>
)

const CloseBtnWrapper = styled.div`
  width 100%;
  margin: 0 auto;
  color: white;
  cursor: pointer;
  &:hover {
    color: hsla(0, 0%, 100%, 0.2);
  }
`
const Cross = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-left: auto;
`
export const CloseBtn = ({ onClick }) => (
  <CloseBtnWrapper onClick={() => onClick()}>
    <Cross>
      <SVG
        width="25"
        height="25"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            d="M0 16L8 8M16 0L8 8M8 8L0 0M8 8L16 16"
            transform="translate(4 4)"
            stroke="#F50A0A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect width="24" height="24" />
        </g>
      </SVG>
    </Cross>
  </CloseBtnWrapper>
)

import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"
import { Section } from "./sections/sections.js"
import Nav from "./navbar"
import "./layout.css"
import { isLoggedIn } from "../services/auth.js"

const Header = styled.header`
  position: ${props => (props.isIndexPage ? "sticky" : "relative")};
  top: 0px;
  z-index: 999;
  background: white;
`
const BodyDiv = styled.div`
  width: 100%;
  height: auto !important;
  height: 100%;
  min-height: 100%;

  @media (max-width: 500px) {
    overflow-x: hidden;
    position: relative;
  }

  background: ${props =>
    props.theme === "dark" ? "rgb(230,230,230)" : "#fff"};
  transition: background 0.3s ease;
  color: #000;
`

const Footer = styled.footer`
  background-color: hsl(0, 0%, 4%);
  color: hsl(0, 0%, 100%, 0.7);
  z-index: 999;
`
const FooterGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 800px) {
    display: flex;
    flex-flow: column nowrap;
  }
`
const ColHeading = styled.h3`
  font-size: 15px;
  font-weight: 700px;
  color: hsl(0, 0%, 100%, 0.9);
`

const Li = styled.li`
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 15px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  line-height: 1.5556;
  &:hover {
    color: hsl(0, 0%, 100%, 1);
  }
`
const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

const Hr = styled.hr`
  height: 0;
  width: 100%;
  border: none;
  outline: none;
  margin: 25px 0;
  border-bottom: 1px solid hsl(0, 0%, 100%, 0.1);
  display: block;
`
const CopyRightContainer = styled.div`
  width: auto;
  margin: 0 auto;
  color: inherit;
`
const ChildWrapper = styled.div`
  width: 100%;
  height: auto !important;
  height: 100%;
  min-height: 100vh;
  z-index: 0;
`

const NotifWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #e84a5f;
  text-align: center;
  padding: 8px 5vw;
  color: white;
  font-size: 15px;
`
const CloseNotif = styled.div`
  display: inline-block;
  cursor: pointer;
  float: right;
`
const Notification = ({ children }) => {
  const [close, setClose] = useState(false)
  if (close) {
    return null
  }
  return (
    <NotifWrap>
      {children}{" "}
      <CloseNotif
        onClick={() => {
          setClose(true)
        }}
      >
        &#10005;
      </CloseNotif>
    </NotifWrap>
  )
}
const courses_v2 = [
  {
    heading: "Medical School",
    items: [
      {
        logo: "M",
        name: "MCAT",
        link: "/mcat",
        description: "Required by most med. schools in North America",
      },
      {
        logo: "C",
        name: "CASPer",
        link: "/casper",
        description: "Required by some med. schools across North America",
      },
    ],
  },
  {
    heading: "Law School",
    items: [
      {
        logo: "L",
        name: "LSAT",
        link: "/lsat",
        description: "Required by almost all law schools across North America",
      },
      {
        logo: "G",
        name: "GRE",
        link: "/gre",
        description:
          "Required by biz. schools, law schools, & Master's programs",
      },
    ],
  },
  {
    heading: "Business School",
    items: [
      {
        logo: "G",
        name: "GMAT",
        link: "/gmat",
        description: "Required by most business schools across North America",
      },
    ],
  },
]
const services_v2 = [
  {
    heading: "Applicable To All Schools",
    items: [
      {
        logo: "C",
        name: "Career Planning",
        link: "/career_planning",
        description:
          "Med. schools require you to score extremely well on this test.",
      },
      {
        logo: "A",
        name: "Applications Advising",
        link: "/editing_applications",
        description: "Some Medical Schools require you to take this test. ",
      },
      {
        logo: "A",
        name: "Interview Preparation",
        link: "/interview_preparation",
        description: "Some Medical Schools require you to take this test. ",
      },
    ],
  },
]
const courses = [
  {
    logo: "M",
    name: "MCAT",
    link: "/mcat",
    description:
      "Med. schools require you to score extremely well on this test.",
  },
  {
    logo: "C",
    name: "CASPer",
    link: "/casper",
    description: "Some Medical Schools require you to take this test.",
  },
  {
    logo: "L",
    name: "LSAT",
    link: "/lsat",
    description: "Based on your GPA, see which schools you can apply to",
  },
  {
    logo: "G",
    name: "GMAT",
    link: "/gmat",
    description:
      "Some Medical Schools require you to take this test. People say you don't need to prepare for this test. People are wrong.",
  },
  {
    logo: "G",
    name: "GRE",
    link: "/gre",
    description: "Calculate your GPA for schools across Canada",
  },
]
const services = [
  {
    logo: "CC",
    name: "Career Planning",
    link: "/career_planning",
    description:
      "Med. schools require you to score extremely well on this test.",
  },
  {
    logo: "A",
    name: "Applications Help",
    link: "/editing_applications",
    description:
      "Some Medical Schools require you to take this test. People say you don't need to prepare for this test. People are wrong.",
  },
]
export const AuthContext = React.createContext(true)
const Layout = ({ children, navColor, notification }) => {
  const [loadJivo, setJivo] = useState(false)
  const [navBasedBackground, setBackground] = useState("light")
  const [loginStatus, setLogin] = useState(() => {
    return isLoggedIn()
  })
  const isIndexPage = () => {
    if (typeof window !== "undefined") {
      return window.location.pathname === "/"
    }
  }

  return (
    <AuthContext.Provider value={[loginStatus, setLogin]}>
      <BodyDiv theme={navBasedBackground}>
        <Header isIndexPage={isIndexPage()}>
          <Nav
            background={navColor}
            coursesDropdown={courses_v2}
            servicesDropdown={services_v2}
            status={loginStatus}
            setLogin={setLogin}
            cb={setBackground}
          />
          {notification === true ? (
            <Notification>
              <strong>Important Notice:</strong> We're currently updating our
              website; certain information and features will be a bit wonky.{" "}
              <em>We're so sorry!</em>
            </Notification>
          ) : null}
        </Header>
        <ChildWrapper>{children}</ChildWrapper>
        <Footer>
          <Section wide>
            <FooterGrid>
              <div>
                <ColHeading>Courses</ColHeading>
                <Ul>
                  <Li>
                    <Link to="/mcat">MCAT</Link>
                  </Li>
                  <Li>
                    <Link to="/casper">CASPer</Link>
                  </Li>
                  <Li>
                    <Link to="/lsat">LSAT</Link>
                  </Li>
                  <Li>
                    <Link to="/gmat">GMAT</Link>
                  </Li>
                  <Li>
                    <Link to="/gre">GRE</Link>
                  </Li>
                </Ul>
              </div>
              <div>
                <ColHeading>Services</ColHeading>
                <Ul>
                  <Li>
                    <Link to="/career_planning">Career Planning</Link>
                  </Li>
                  <Li>
                    <Link to="/editing_applications">
                      Applications Advising
                    </Link>
                  </Li>
                  <Li>
                    <Link to="/interview_preparation">
                      Interview Prepartion
                    </Link>
                  </Li>
                </Ul>
              </div>

              <div>
                <ColHeading>Company</ColHeading>
                <Ul>
                  <Li>
                    <Link to="/about">About Us</Link>
                  </Li>
                  <Li>Careers</Li>
                  <Li>Events</Li>
                  <Li>Media</Li>
                  <Li>
                    {" "}
                    <Link to="/contact">Contact Us</Link>
                  </Li>
                </Ul>
              </div>
              <div>
                <ColHeading>Our Core Team</ColHeading>
                <Ul>
                  <Li>
                    <Link to="/instructors/sophia_glisch">Sophia Glisch</Link>
                  </Li>

                  <Li>
                    <Link to="/instructors/humayun_ahmed">Humayun Ahmed</Link>
                  </Li>
                  <Li>
                    <Link to="/instructors/sohaib_ahmed">Sohaib Ahmed</Link>
                  </Li>
                  <Li>
                    <Link to="/">Dagmar Vyoral</Link>
                  </Li>
                </Ul>
              </div>
              <div>
                <ColHeading>More</ColHeading>
                <Ul>
                  <Li>Privacy Policy</Li>
                  <Li>Terms Of Use</Li>
                  <Li>Score Guarentee</Li>
                  <Li>Cookies</Li>
                  <Li>Security</Li>
                  <Li>SiteMap</Li>
                </Ul>
              </div>
            </FooterGrid>
            <Hr />
            <CopyRightContainer>
              Copyright © 2021 99point9 Inc.{" "}
            </CopyRightContainer>
          </Section>
        </Footer>
      </BodyDiv>
    </AuthContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

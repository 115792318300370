import React from "react"
import styled from "styled-components"
import { OptionsHorizontal } from "../../images/icons.js"

const DropBtn = styled.button`
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
`
const DropdownContentWrap = styled.a`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
`
const DropdownContent = styled.div`
  display: none;
  position: absolute;
  right: -75px;
  top: 25px;
  background-color: rgb(40, 40, 40);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;

  & ${DropdownContentWrap}:hover {
    background-color: #f50057;
  }
`
const Dropdown = styled.div`
  position: relative;
  right: 0;
  width: 110px;
  margin: 25px auto;
  display: inline-block;
  &:hover ${DropdownContent} {
    display: block;
  }
  &:hover ${DropBtn} {
    background-color: ${props => (props.hoverColor ? props.hoverColor : "")};
  }
`

export const Dropdown_v1 = props => {
  const fetchData = () => {}

  return (
    <Dropdown>
      <DropBtn>Dropdown</DropBtn>
      <DropdownContent>
        <DropdownContentWrap onClick={() => fetchData(0, "pending")}>
          Pending
        </DropdownContentWrap>
        <DropdownContentWrap onClick={() => fetchData(0, "inactive")}>
          Inactive
        </DropdownContentWrap>
        <DropdownContentWrap onClick={() => fetchData(0, "active")}>
          Active
        </DropdownContentWrap>
        <DropdownContentWrap onClick={() => fetchData(0, "")}>
          All
        </DropdownContentWrap>
      </DropdownContent>
    </Dropdown>
  )
}

const IconWrap = styled(Dropdown)`
  padding: 16px;
`
export const OptionsDropdown = ({ children }) => {
  return (
    <>
      <Dropdown>
        <OptionsHorizontal
          stroke="#9da7aa"
          hoverColor="#F50057"
          padding="40px"
        />
        <DropdownContent>
          {children &&
            children.map((child, index) => (
              <DropdownContentWrap>{child}</DropdownContentWrap>
            ))}
        </DropdownContent>
      </Dropdown>
    </>
  )
}
export default Dropdown_v1

import React from "react"
import styled from "styled-components"

let iconHeight = "22"
let iconWidth = "22"
let sidebarColor = "rgb(245, 0, 87, 0.1)"
let primaryPink = "rgb(20,20,20)" //"rgb(20,20,20)" //"#f50057"
let iconColor = "black"
let pink = "#F50057"

const SVG = styled.svg`
  &:active {
    fill: #f50057;
  }
`
const SvgWrap = styled.div`
  box-sizing: border-box;
  padding: 8px;
  float: left;
  border: none;
  display: block;
  outline: 0;
  color: white;
  text-align: center;
  background-color: ${props => (props.active ? "blue" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  flex-flow: column nowrap;
  cursor: pointer;
  margin: 2px;
  border: 1px solid transparent;
  & span {
    color: ${props => (props.stroke ? props.stroke : iconColor)};
    margin: 2px 0px;
  }
  & svg path {
    stroke: ${props => (props.stroke ? props.stroke : iconColor)};
  }
  &:hover {
    background-color: transparent;
    border: 1px solid ${pink};
    & span {
      color: ${props => (props.hoverColor ? props.hoverColor : primaryPink)};
    }
    & svg path {
      stroke: ${props => (props.hoverColor ? props.hoverColor : primaryPink)};
    }
  }
`

const IconWrap = props => (
  <SvgWrap stroke={props.stroke} hoverColor={props.hoverColor}>
    {props.children}
  </SvgWrap>
)

export const Cross = props => (
  <SVG
    width="25"
    height="25"
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="18"
      height="18"
    >
      <path
        d="M0 16L8 8M16 0L8 8M8 8L0 0M8 8L16 16"
        transform="translate(4 4)"
        stroke="#F50A0A"
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" />
    </g>
  </SVG>
)
export const Hamburger = props => (
  <SVG viewBox="0 0 100 80" width="25" height="25">
    <rect width="100" height="20" rx="8"></rect>
    <rect y="30" width="100" height="20" rx="8"></rect>
    <rect y="60" width="100" height="20" rx="8"></rect>
  </SVG>
)
export const Home = props => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height={(props && props.height) || "48"}
    width={(props && props.height) || "48"}
  >
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.5 7 7 .5 13.5 7m-11 1.5v5h9v-5"
      ></path>
    </g>
  </SVG>
)
export const SettingSliderHorizontal = props => {
  return (
    <SVG
      viewBox="0 0 48 48"
      height={(props && props.height) || "48"}
      width={(props && props.width) || "48"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <path
          d="M0.500 2.000 A1.500 1.500 0 1 0 3.500 2.000 A1.500 1.500 0 1 0 0.500 2.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M3.5 2L13.5 2"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M0.5 7L5.5 7"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M8.5 7L13.5 7"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.500 12.000 A1.500 1.500 0 1 0 13.500 12.000 A1.500 1.500 0 1 0 10.500 12.000 Z"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M10.5 12L0.5 12"
          fill="none"
          stroke={(props && props.stroke) || "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </SVG>
  )
}
export const ContentBookEdit = props => (
  <SVG
    viewBox="0 0 48 48"
    height={(props && props.height) || "48"}
    width={(props && props.height) || "48"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path
        d="M8.5,13.5h-7a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h8"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.5,3.5,12,.5l1.5,3V12a1.5,1.5,0,0,1-3,0Z"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.5 9.5L13.5 9.5"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M3.5 0.5L3.5 13.5"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M5.5 3.5L8.5 3.5"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </SVG>
)
export const AlignVerticalBottom = props => (
  <SVG
    viewBox="0 0 48 48"
    height={(props && props.height) || "48"}
    width={(props && props.height) || "48"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path
        d="M13.5 13.5L0.5 13.5"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.250 5.750 L13.750 5.750 L13.750 9.750 L7.250 9.750 Z"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(2.75 18.25) rotate(-90)"
      ></path>
      <path
        d="M-1.750 3.750 L8.750 3.750 L8.750 7.750 L-1.750 7.750 Z"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-2.25 9.25) rotate(-90)"
      ></path>
    </g>
  </SVG>
)
export const ContentBook = props => (
  <SVG
    viewBox="0 0 48 48"
    height={(props && props.height) || "48"}
    width={(props && props.height) || "48"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
      <path
        d="M12.5,13.5H3a1.5,1.5,0,0,1,0-3h8.5a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1H3A1.5,1.5,0,0,0,1.5,2V12"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.5 10.5L11.5 13.5"
        fill="none"
        stroke={(props && props.stroke) || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </SVG>
)
export const OptionsVertical = props => (
  <IconWrap
    stroke={props.stroke ? props.stroke : "#000000"}
    hoverColor={props.hoverColor ? props.hoverColor : "#000000"}
  >
    <SVG
      viewBox="0 0 48 48"
      height={iconHeight}
      width={iconWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <path
          d="M5.500 2.000 A1.500 1.500 0 1 0 8.500 2.000 A1.500 1.500 0 1 0 5.500 2.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.500 12.000 A1.500 1.500 0 1 0 8.500 12.000 A1.500 1.500 0 1 0 5.500 12.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </SVG>
  </IconWrap>
)
export const OptionsHorizontal = props => (
  <IconWrap
    stroke={props.stroke ? props.stroke : "#000000"}
    hoverColor={props.hoverColor ? props.hoverColor : "#000000"}
  >
    <SVG
      viewBox="0 0 48 48"
      height={iconHeight}
      width={iconWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
        <path
          d="M10.500 7.000 A1.500 1.500 0 1 0 13.500 7.000 A1.500 1.500 0 1 0 10.500 7.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M5.500 7.000 A1.500 1.500 0 1 0 8.500 7.000 A1.500 1.500 0 1 0 5.500 7.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M0.500 7.000 A1.500 1.500 0 1 0 3.500 7.000 A1.500 1.500 0 1 0 0.500 7.000 Z"
          fill="none"
          stroke={props.stroke ? props.stroke : "#000000"}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </SVG>
  </IconWrap>
)

export const CalendarDone = props => {
  return (
    <IconWrap
      stroke={props.stroke ? props.stroke : "#000000"}
      hoverColor={props.hoverColor ? props.hoverColor : "#000000"}
    >
      <SVG
        viewBox="0 0 48 48"
        height={iconHeight}
        width={iconWidth}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
          <path
            d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
          <path
            d="M3.5 0.5L3.5 4.5"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
          <path
            d="M10.5 0.5L10.5 4.5"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
          <path
            d="M3.5 2.5L8.5 2.5"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
          <path
            d="M4 9L6 10.5 9.5 6.5"
            fill="none"
            stroke={props.stroke ? props.stroke : "#000000"}
            strokeLinecap="round"
            strongLinejoin="round"
          ></path>
        </g>
      </SVG>
    </IconWrap>
  )
}

export const CalendarPending = props => {
  return (
    <IconWrap
      stroke={props.stroke ? props.stroke : "#000000"}
      hoverColor={props.hoverColor ? props.hoverColor : "#000000"}
    >
      <SVG
        viewBox="0 0 48 48"
        height={iconHeight}
        width={iconWidth}
        xmlns="http://www.w3.org/2000/svg"
      >
        {props.done ? (
          <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
            <path
              d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
            <path
              d="M3.5 0.5L3.5 4.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
            <path
              d="M10.5 0.5L10.5 4.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
            <path
              d="M3.5 2.5L8.5 2.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
            <path
              d="M4 9L6 10.5 9.5 6.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strongLinejoin="round"
            ></path>
          </g>
        ) : (
          <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)">
            <path
              d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3.5 0.5L3.5 4.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M10.5 0.5L10.5 4.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3.5 2.5L8.5 2.5"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M8.768 9.768L5.232 6.232"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M8.768 6.232L5.232 9.768"
              fill="none"
              stroke={props.stroke ? props.stroke : "#000000"}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        )}
      </SVG>
    </IconWrap>
  )
}

// import axios from "axios"
import { sendLogin, sendLogout } from "./apiRequests.js"

// axios.defaults.baseURL = "https://sophiaglisch.ca"
// axios.defaults.baseURL = "http://localhost:9000"

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("email")
    ? JSON.parse(window.localStorage.getItem("email"))
    : ""

const setUser = user =>
  window.localStorage.setItem("email", JSON.stringify(user))

export const handleLogin = (creds, cb) => {
  sendLogin(creds, (err, data) => {
    if (err) {
      // console.log(err.response)
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status < 500
      ) {
        // console.log(err.response)
        cb("Oops! We think you typed an incorrect email. It's invalid.")
      } else if (err.response && err.response.status >= 500) {
        cb(
          "Yikes. Something went wrong on our end - it's def. not you. Please try again in a little bit, or reach out to via email (info@99point9prep.com)"
        )
      } else {
        cb(
          "Uh oh! Looks like there's something up with your internet connection: we're unable to connect you to our website. Please either try again later, or reach out to us in another way (you could email us at info@99point9prep.com)"
        )
      }
    } else {
      setUser(data)
      cb(null, true)
    }
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user
}

export const logout = callback => {
  const creds = getUser()
  setUser("")
  sendLogout(creds, data => {
    callback()
  })
}

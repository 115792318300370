import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { HoverArrow, MenuButton } from "./navbar.js"

const DropdownContainer = styled.div`
  position: absolute;
  top: 50px !important;
  width: 100%;
  min-height: ${props => (props.open ? "250px" : "0px")};
  height: ${props => (props.open ? "auto" : "0px")};
  visibility: ${props => (props.open ? "visible" : "hidden")};
  opacity: ${props => (props.open ? "1" : "0")};
  background: rgb(250, 250, 250, 1);
  transition: all 0.5s ease-in-out;
  backdrop-filter: saturate(180%) blur(10px);
  z-index: 9999;
  margin-top: 0;
  border-bottom: 1px solid hsl(0, 0%, 100%, 0.1);
`
const ServicesContainer = styled.div`
  display: flex;
  flow-flow: row nowrap;
  justify-content: center;
  position: absolute;
  left: 0px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
`

// const CardLogoContainer = styled.div`
//   width: 100%;
//   height: 2em;
//   padding: 10% 0;
//   border-bottom: 1px solid rgb(220, 220, 220);
//   margin-bottom: 30%;
// `
// const CardLogo = styled.div`
//   font-weight: 500;
//   font-size: 15px;
//   padding: 0.5em;
//   text-align: left;
// `

// const CardDescrip = styled.div`
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 19px;
//   margin-bottom: 20%;
// `
const cardColors = ["#0F9B2D", "#12AFC3", "#BD43DE", "#5C44CC", "#4072F8"]

const ServiceCard = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  max-width: 20%;
  padding: 32px 20px 32px 32px;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  max-width: 15%;
  height: 281px;
  padding: 10px;
  margin: 0;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${props => props.backgroundcolor};
    backdrop-filter: saturate(180%) blur(10px);
    background-opacity: 0.7;
    transform: scale(1.03);
    color: white;
  }
`
const MenuGroup = styled.div`
  // opacity: 1;
  // transform: translate3d(15px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
  //   rotateZ(0) skew(0, 0);
  // transform-style: preserve-3d;
`
const MenuTopRow = styled.div`
  display: flex;
  padding: 0px 15px 0px 15px;
  align-items: flex-end;
`
const MenuBottomRow = styled.div`
  display: flex;
  position: relative;
  top: 10px;
  z-index: 9999;
`
const MenuBorder = styled.div`
  position: relative;
  bottom: -12px;
  z-index: 9999;
  width: 100%;
  height: 40px;
  border-bottom: 2px solid #424242;
`
const MenuHeader = styled.div`
  margin-top: 10px;
  padding-bottom: 0;
  color: #424242;
  font-weight: 600;
  family-font: Montserrat sans-serif;
`
const MenuWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  justify-content: center;
  align-items: stretch;
`
const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
`
const Icon = styled.div`
  position: relative;
  left: -5px;
  width: 30px;
  color: #424242;
`
const MenuTitle = styled.div`
  color: #000;
  font-size: 15px;
  font-weight: 700;
`
const MenuPara = styled.div`
  margin-top: 10px;
  color: #424242;
  font-size: 11px;
  width: 100%;
`
const LinkRow = styled(Link)`
  // flex: 1 1 0px
  position: relative;
  z-index: 2;
  display: flex;
  height: 167px;
  padding: 16px 20px 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-decoration: none;
  max-width: 100%;
  &:hover {
    background-color: ${props => props.backgroundcolor};
  }
`

const NavHover = ({ open, toggleNav, cardData }) => {
  return (
    <DropdownContainer
      open={open}
      onMouseEnter={() => toggleNav(true)}
      onMouseLeave={() => {
        toggleNav(false)
      }}
    >
      {/* <ServicesContainer> */}
      {/* cardData is coming from layout.js */}
      <ServicesContainer>
        {cardData.map((cardgroup, ind) => (
          <div key={cardgroup.heading + ind}>
            <MenuTopRow>
              <MenuBorder>
                <MenuHeader>{cardgroup.heading}</MenuHeader>
              </MenuBorder>
            </MenuTopRow>
            <MenuBottomRow>
              {cardgroup.items.map((cardItem, index) => (
                <LinkRow
                  key={cardItem.name + "ServiceCard" + index}
                  to={cardItem.link}
                  backgroundcolor={
                    index + ind < 5
                      ? cardColors[index + ind]
                      : cardColors[index + ind - 5]
                  }
                >
                  <MenuTitle>{cardItem.name}</MenuTitle>
                  <MenuPara>{cardItem.description}</MenuPara>
                </LinkRow>
              ))}
            </MenuBottomRow>
          </div>
        ))}
      </ServicesContainer>
      {/* </ServicesContainer> */}
    </DropdownContainer>
  )
}
export default NavHover
const MobileDropdownWrap = styled.div`
  height: ${props => (props.open ? "auto" : "0px")};
  visibility: ${props => (props.open ? "visible" : "hidden")};
  opacity: ${props => (props.open ? "1" : "0")};
  position: absolute;
  width: 95%;
  margin: 0px 2.5%;
  min-height: 80px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 9999;
  box-sizing: border-box;
  @media (min-width: 880px) {
    display: none;
  }
  @media (max-width: 400px) {
    width: 300px;
    margin: 0 auto;
  }
`
const DropdownSectionWrap = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  border-bottom: 1px dotted black;
`
const DropdownHeading = styled.h4`
  margin-bottom: 18px;
  font: 500 13px/1.538;
  width: 100%;
`
const DropdownSectionItems = styled.section`
  display: flex;
`
const SignSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`
const MobileNavWrap = styled.button`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  color: none;
  align-items: center;
  @media (min-width: 880px) {
    display: none;
  }
`
const SVG = styled.svg`
  &:active {
    fill: #f50057;
  }
`
const LinksWrap = styled.section`
  display: flex;
  flex-flow: row wrap;
`
const NavigationLinks = styled(Link)`
  padding: 6px;
  min-width: 150px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #f50057;
  }
`
export const MobileDropdown = ({ navOpen, toggleNav }) => {
  return (
    <MobileDropdownWrap open={navOpen} toggleNav={toggleNav}>
      <DropdownSectionWrap>
        <DropdownHeading>Courses</DropdownHeading>
        <LinksWrap>
          <NavigationLinks to="/mcat">MCAT</NavigationLinks>
          <NavigationLinks to="/casper">CASPEr</NavigationLinks>
          <NavigationLinks to="/lsat">LSAT</NavigationLinks>
          <NavigationLinks to="/gmat">GMAT</NavigationLinks>
          <NavigationLinks to="/gre">GRE</NavigationLinks>
        </LinksWrap>
      </DropdownSectionWrap>
      <DropdownSectionWrap>
        <DropdownHeading>Services</DropdownHeading>
        <LinksWrap>
          <NavigationLinks to="/career_planning">
            Career Planning
          </NavigationLinks>
          <NavigationLinks to="/editing_applications">
            Applications Advising
          </NavigationLinks>
          <NavigationLinks to="/interview_preparation">
            Interview Preparation
          </NavigationLinks>
        </LinksWrap>
      </DropdownSectionWrap>
      <DropdownSectionWrap>
        <LinksWrap>
          <NavigationLinks to="/instructors">Instructors</NavigationLinks>
          <NavigationLinks to="/about">About</NavigationLinks>
          <NavigationLinks to="/contact">Contact</NavigationLinks>
        </LinksWrap>
      </DropdownSectionWrap>
      <SignSection>
        <MenuButton color={"#F50057"}>
          Sign In
          <HoverArrow
            width="10"
            height="10"
            viewBox="0 0 10 10"
            aria-hidden="true"
          >
            <g fillRule="evenodd">
              <path className="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
            </g>
          </HoverArrow>
        </MenuButton>
      </SignSection>
    </MobileDropdownWrap>
  )
}
export const MobileNav = ({ onClickCb, navOpen }) => {
  const [showMenu, toggleMenu] = useState(false)
  return (
    <MobileNavWrap onClick={() => onClickCb()}>
      {!!navOpen ? (
        <SVG
          width="25"
          height="25"
          viewBox="0 0 24 24"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="18"
            height="18"
          >
            <path
              d="M0 16L8 8M16 0L8 8M8 8L0 0M8 8L16 16"
              transform="translate(4 4)"
              stroke="#F50A0A"
              stroke-width="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect width="24" height="24" />
          </g>
        </SVG>
      ) : (
        <SVG viewBox="0 0 100 80" width="25" height="25">
          <rect width="100" height="20" rx="8"></rect>
          <rect y="30" width="100" height="20" rx="8"></rect>
          <rect y="60" width="100" height="20" rx="8"></rect>
        </SVG>
      )}
    </MobileNavWrap>
  )
}

import React, { useRef, useState } from "react"

import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { logout } from "../services/auth"
import { SameSiteAnchor } from "./elements/buttons.js"
// import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import NavHover, { MobileNav, MobileDropdown } from "./navHover.js"
import Cart from "../images/cart.svg"

const Navbar = styled.div`
  min-height: 56px;
  box-sizing: border-box;
  background: ${props => (props.background ? props.background : "white")};
  z-index: 999;
  position: relative;

  display: flex;
  justify-content: space-between;
`
const NavWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 90vw;
  padding: 0 16px;
  margin: 0 auto;
  height: inherit;
  position: relative;
  z-index: 999;
  @media (max-width: 880px) {
    padding: 0px;
  }
`
const NavCenter = styled.nav`
  @media (max-width: 880px) {
    display: none;
  }
`
const Ul = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  top: 1px;
  @media (max-width: 880px) {
    display: none;
  }
`
const transition = `150ms cubic-bezier(0.215,0.61,0.355, 1)`
const NavButton = styled.button`
  cursor: default;
  transition: ${transition};
  transition-property: color, opacity;
  color: #000;
  padding: 20px 20px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.2px;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`
export const HoverArrow = styled.svg`
  position: relative;
  top: 1px;
  margin-left: 8px;
  stroke-width: 2;
  fill: none;
  stroke: currentColor;
`
export const MenuButton = styled.div`
  height: ${props => (props.height ? props.height : "34px")};
  font-size: 15px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: ${props =>
    props.height && parseInt(props.height, 10) > -1
      ? (parseInt(props.height, 10) / 2).toString() + "px 16px"
      : "16px"};
  border-radius: 50vh;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "hsla(0, 0%, 100%, 0.2)"};
  color: ${props => (props.color ? props.color : "#fff")};
  transition: ${transition};
  transition-property: background-color, opacity;
  cursor: pointer;
  &:hover ${HoverArrow} {
    transition: ${transition};
    transform: translateX(3px);
  }
`

const CartImg = styled.img`
  height: 15px;
  position: relative;
  top: -9px;
  padding: 0px 1em;
  transform: scale(2.5);
  stroke: blue !imp;
`
const AmILoggedInNav = styled.nav`
  display: block;
  @media (max-width: 880px) {
    display: none;
  }
`
const AmILoggedInOptions = props => {
  const handleLogout = e => {
    e.preventDefault()
    logout(() => navigate("/"))
  }
  return (
    <AmILoggedInNav>
      {props.status !== true ? (
        <Link to="/app/dashboard">
          <CartImg src={Cart} alt="Shopping Cart" />

          <MenuButton color={"#F50057"}>
            Sign In
            <HoverArrow
              width="10"
              height="10"
              viewBox="0 0 10 10"
              aria-hidden="true"
            >
              <g fillRule="evenodd">
                <path className="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
              </g>
            </HoverArrow>
          </MenuButton>
        </Link>
      ) : (
        <>
          <MenuButton
            color={"#F50057"}
            onClick={() => {
              navigate("/app/dashboard")
            }}
          >
            My Profile
          </MenuButton>
          <MenuButton
            color={"#F50057"}
            onClick={e => {
              props.setLogin(false)
              handleLogout(e)
            }}
          >
            Sign Out
          </MenuButton>
        </>
      )}
    </AmILoggedInNav>
  )
}
const Nav = ({
  coursesDropdown,
  servicesDropdown,
  status,
  setLogin,
  background,
  isIndexPage,
  cb,
}) => {
  const [navOpen, setOpen] = useState(false)
  const [cardData, setCardData] = useState(coursesDropdown)
  const [mobileNavOpen, toggleMobileNav] = useState(false)

  const toggleHover = boolean => {
    if (boolean !== undefined) {
      setOpen(boolean)
      cb(boolean === true ? "dark" : "light")
    } else {
      setOpen(!navOpen)
    }
  }
  return (
    <>
      <Navbar background={background}>
        <NavWrap>
          <Link to="/">
            <h3 style={{ color: "#F50057", fontWeight: "600" }}>99point9</h3>
          </Link>
          <NavCenter>
            <Ul>
              <li>
                <NavButton
                  onMouseEnter={() => {
                    setCardData(coursesDropdown)
                    toggleHover(true)
                  }}
                  onMouseLeave={() => {
                    toggleHover(false)
                  }}
                >
                  <Link to="/mcat">Courses</Link>
                </NavButton>
              </li>

              <li>
                <NavButton
                  onMouseEnter={() => {
                    setCardData(servicesDropdown)
                    toggleHover(true)
                  }}
                  onMouseLeave={() => {
                    toggleHover(false)
                  }}
                >
                  Services
                </NavButton>
              </li>
              <li>
                <Link to="/instructors">
                  <NavButton>Instructors</NavButton>
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <NavButton>About</NavButton>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <NavButton>Contact</NavButton>
                </Link>
              </li>
            </Ul>
          </NavCenter>
          <AmILoggedInOptions status={status} setLogin={setLogin} />
          <MobileNav
            onClickCb={() => {
              toggleMobileNav(!mobileNavOpen)
            }}
            navOpen={mobileNavOpen}
          />
        </NavWrap>
      </Navbar>
      <NavHover cardData={cardData} open={navOpen} toggleNav={toggleHover} />
      <MobileDropdown navOpen={mobileNavOpen} toggleNav={toggleMobileNav} />
    </>
  )
}

export default Nav

/* IN PAGE NAVBAR ==> Used for courses*/
const InPageNavbar = styled(Navbar)`
  width: 100%;
  border-bottom: 1px solid rgb(0, 0, 0, 0.01);
  margin: 0 auto;
  width: 90vw;
  padding: 0 16px;
  color: inherit;
  align-items: center;
  background-color: transparent;
  @media (max-width: 485px) {
    padding: 0 12px;
    width: 95vw;
  }
`
const InPageNavWrap = styled.div`
  border-bottom: 1px solid rgb(0, 0, 0, 0.01);
  width: 100%;
  justify-content: center;
  position: sticky;
  top: -1px;
  min-height: 3em;
  background-color: ${props =>
    !!props.theme && props.theme === "dark" ? "transparent" : "transparent"};
  color: ${props =>
    !!props.theme && props.theme === "dark" ? "white" : "black"};
  // background-color: #fff;
  backdrop-filter: saturate(180%) blur(10px);
  z-index: 998;
`

const NavContainer = styled.div`
  margin-left: auto;
  color: inherit;
  transition: all 0.2s ease;
  float: right;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
`
const NavTitle = styled.h4`
  margin: 0;
`

export const InPageNav = ({
  theme,
  navItems,
  clickCallback,
  currentSection,
  pageTitle,
  currentPath,
}) => {
  const navRef = useRef(null)
  const handleCallback = (link, label) => {
    // clickCallback(link)
    //Note: removed this since I added "Link" to Anchor in button styles
  }

  return (
    <InPageNavWrap theme={theme} ref={navRef}>
      <InPageNavbar>
        <NavTitle>{pageTitle}</NavTitle>
        <NavContainer>
          {navItems.map((item, index) => {
            const checkActive = (currentPath, itemLink) => {
              if (currentPath) {
                let currPath = currentPath.substring(0)
                let link = item.link
                while (currPath[currPath.length - 1] === "/") {
                  currPath = currPath.substring(0, currPath.length - 1)
                }
                while (link[link.length - 1] === "/") {
                  link = link.substring(0, link.length - 1)
                }

                return currPath === link
              }
            }
            return (
              <SameSiteAnchor
                active={checkActive(currentPath, item.link)}
                link={item.link}
                label={item.label}
                key={item + index + "anchor"}
                callback={handleCallback}
                href={item.link}
              />
            )
          })}
        </NavContainer>
      </InPageNavbar>
    </InPageNavWrap>
  )
}
